import React from "react"
import { Main, SpanBox, Shadow } from "./style"

const Header = ({ slider1, slider2 }) => {
  return (
    <Main>
      <Shadow />
      <SpanBox>
        <h1>{slider1}</h1>
        <h2>{slider2}</h2>
      </SpanBox>
    </Main>
  )
}

export default Header
