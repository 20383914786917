import styled from "styled-components"

export const Main = styled.div`
  height: 472px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;

  @media (max-width: 1149px) {
    height: 503px;
  }

  @media (max-width: 799px) {
    height: 678px;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: 35px repeat(3, 60px); */
  gap: 20px 20px;

  grid-template-areas:
    "title title title"
    "property-type location location"
    "transaction-type price floorage"
    ". . submit";

  @media (max-width: 1149px) {
    width: 82.5%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 35px repeat(4, 64px);

    grid-template-areas:
      "title title"
      "property-type transaction-type"
      "location location"
      "price floorage"
      "submit submit";
  }

  @media (max-width: 799px) {
    width: 88.888%;
    grid-template-columns: 1fr;
    grid-template-rows: 70px repeat(6, 64px);

    grid-template-areas:
      "title"
      "property-type"
      "transaction-type"
      "location"
      "price"
      "floorage"
      "submit";
  }

  #title {
    grid-area: title;
  }

  #propertyType {
    grid-area: property-type;
  }

  #location {
    grid-area: location;
  }

  #transactionType {
    grid-area: transaction-type;
  }

  #price {
    grid-area: price;
  }

  #floorage {
    grid-area: floorage;
  }

  #submit {
    grid-area: submit;
  }
`

export const WrapperTitle = styled.p`
  background: linear-gradient(
    90deg,
    #eae69c -4.56%,
    #bb913c 11.77%,
    #b6872d 54.25%,
    #eae69c 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 3.45rem;
  letter-spacing: 0.06em;
`

export const StyledInput = styled.input`
  border: none;
  background: #111111;
  border-radius: 2px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  padding: 20px 15px 19px 15px;
  outline: none;
  border: 1px solid transparent;

  transition: color 0.2s, border-color 0.2s;
  width: 100%;
  /* ${props =>
    props.isActive
      ? `
      display:flex;
    `
      : `display:none`} */

  &[value]:not([value=""]) {
    background-color: black;
    width: 100%;
    border: none;
    color: transparent;
    background: linear-gradient(
      90deg,
      #ce9638 5.35%,
      #eae69c 7.05%,
      #b6872d 52.34%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::placeholder {
    color: #888;
    opacity: 1;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

export const CombinedInput = styled.div`
  position: relative;

  .placeholder {
    position: absolute;

    width: 100%;

    ${props =>
      props.isActive
        ? `
      width: 0;
      opacity: 0;
      pointer-events: none;
    `
        : ``}

    transition: width 0.5s, opacity 0.5s;
  }

  .children {
    display: flex;

    gap: 20px;

    > * {
      width: 100%;
    }
  }
`

export const GoldButton = styled.button`
  text-decoration: none;
  background: linear-gradient(
    90deg,
    #ce9638 0%,
    #eae69c 22.4%,
    #ce9638 48.44%,
    #b6872d 77.6%,
    #bb913c 90%,
    #ce9638 100%
  );
  min-height: 64px;
  min-width: 320px;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  line-height: 2.1rem;
  color: #030303;
  transition: all 0.15s ease-in;

  &:hover {
    background-position: 75px 0;
  }
`

export const InsideOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.06em;
  text-align: left;
`

export const InsideSpan = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.06em;
  text-align: right;
  color: #6e6e6e;
`
