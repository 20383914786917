import styled from "styled-components"
import pricingBackground from "../../../../images/pricing-background.jpg"

export const SpanBox = styled.div`
  margin-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  gap: 13px;

  @media (max-width: 550px) {
    gap: 25px;
  }
`

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #030303;
  opacity: 0.6;
  aspect-ratio: 16/9;

  @media (max-width: 1149px) {
    height: 100vh;
    min-height: 650px;

    width: 100%;
  }
`

export const Main = styled.div`
  background: url(${pricingBackground}) top center no-repeat fixed;
  height: 100vh;
  width: 100%;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8.5rem;
  gap: 13px;
  position: relative;
  aspect-ratio: 16/9;

  @supports (-webkit-touch-callout: none) {
    background: url(${pricingBackground}) center top no-repeat scroll;
    background-size: auto 100vh;
  }

  @media (max-width: 1149px) {
    width: 100%;
    height: 100vh;

    background-size: cover;
  }

  @media (max-width: 550px) {
    gap: 25px;
  }

  h1 {
    z-index: 4;
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 5.4rem;
    letter-spacing: 0.05em;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    color: #fdfdfd;
    max-width: 500px;
  }

  h2 {
    z-index: 4;
    font-size: 2.3rem;
    line-height: 3.5rem;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fdfdfd;
    font-weight: 700;
  }
`

export const LogoBox = styled.div`
  width: 521px;
  height: 201px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  border: 2px solid #fdfdfd;

  img {
    width: 363px;
    height: 111px;
  }

  @media (max-width: 550px) {
    width: 400px;
    height: 180px;
    border: none;
    border-bottom: 2px solid #fdfdfd;
  }
`
